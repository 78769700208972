import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../_services/admin.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { NotificationService } from '../_services/notification.service';
import { PhoneValidator } from '../_util/phone.validator';
import moment from 'moment';
import { Paginator } from 'primeng/primeng';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss'],
  providers: [ConfirmationService],
})
export class LogComponent implements OnInit {
  searchInput: any = ({});
  paginatorData: any = ({});
  tableData: any;
  selectedDetail: any; 
  cols: any[];
  isLoading = true;
  isdetailLoading = true;
  addModal = false;
  detailModal = false;
  editModal = false;
  addAgentModal = false;
  createLandingForm: FormGroup;
  detailForm: FormGroup;
  updateLandingForm: FormGroup;
  formSubmitted = false;
  addNewAgent: boolean = false;
  totalRecords: number = 0;
  currentPage: number = 1;
  page: number = 0;
  pageLimit: number = 15;
  pageCount: number = 1;
  agents: SelectItem[] = [];
  users: SelectItem[] = [];
  types: SelectItem[] = [];

  constructor(private adminService: AdminService, private formBuilder: FormBuilder, private authService: AuthService, private notificationService: NotificationService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
      this.paginatorData = {
        first: 1,
        rows: 15,
        sortField: null,
        sortOrder: null,
    };
    this.cols = [
      { field: 'type', header: 'Type' },
      { field: 'user', header: 'User' },
      { field: 'agent', header: 'Agent' },
      { field: 'formattedDate', header: 'Created Date' },
      { field: 'detail', header: 'Detail' }
    ];
    this.types = [
      { label: 'login', value: 'login' },
      { label: 'lead', value: 'lead' },
      { label: 'availability', value: 'availability' },
      { label: 'duplicate', value: 'duplicate' },
      { label: 'deposit', value: 'deposit' },
      { label: 'activity', value: 'activity' },
      { label: 'payment', value: 'payment' }
    ];
    this.getLogs({});
    this.getAgents();
    this.getUsers();
    this.detailForm = this.formBuilder.group({
      detail: ['', [Validators.required]],
    });
  }
  
  onPage(event: any){
    this.paginatorData = event;
    console.log(event);
    if (Object.keys(this.searchInput).length > 0) {
      this.searchLogs(event);
  }
  else {
      this.getLogs(event);
  }
  }
  getLogs(event: any) {
    this.isLoading = true;
    this.currentPage = event.page + 1;  
    this.adminService.getLogswPagination(this.currentPage, this.pageLimit).subscribe(data => {
      const logs = data.logs
      this.totalRecords = data.totalRecords;
      this.tableData = logs.map(item => {
        return {
          ...item,
          formattedDate: moment(item.createdDate).format('DD.MM.YYYY HH:mm')
        };
      });
      this.isLoading = false;
    });
  }

  searchLogs(event: any) {
    this.isLoading = true;
    this.currentPage = event.page + 1;
    this.adminService.searchLogs(this.currentPage, this.pageLimit, this.searchInput).subscribe(data => {
      const logs = data.logs
      this.totalRecords = data.totalRecords;
      this.tableData = logs.map(item => {
        return {
          ...item,
          formattedDate: moment(item.createdDate).format('DD.MM.YYYY HH:mm')
        };
      });
      this.isLoading = false;
    });
  }
  
  showDetails(event) {
    this.detailModal = true;
    this.isdetailLoading = true;
    this.selectedDetail = event; // Örneğin ilk logun detayını kullanıyoruz
    this.isdetailLoading = false;
  
  }
  
  getAgents() {
    this.adminService.getAgents().subscribe(data => {
      console.log(data, "<<<<<agents>>>>>");
      const hede = [];
      data.map((line) => {
        if(!line.hidden) {
          hede.push({label: line.name, value: line._id});
        }
      });
      this.agents = hede;
    });
  }

  clearSearchForm() {
    this.searchInput = {};
    this.getLogs({page: 0});
}

  getUsers() {
    this.adminService.getUsers().subscribe(data => {
      console.log(data, "<<<<<users>>>>>");
      const hede = [];
      data.map((line) => {
          hede.push({label: line.name, value: line._id});
      });
      this.users = hede;
    });
  }
  // getLog() {
  //   this.adminService.getLogs().subscribe(data => {
  //     const types = [];

  //     for (const item of data) {
  //       if (!types.includes(item.type)) {
  //         types.push(item.type);
  //       }
  //     }
  //     this.types = types.map(type => ({ label: type, value: type }));
  //   });
  // }

  addTeam() {
    this.addModal = true;
  }

  openAddAgent() {
    this.addAgentModal = true;
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../_services/admin.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { NotificationService } from '../_services/notification.service';
import { PhoneValidator } from '../_util/phone.validator';
import moment from 'moment';
import { Paginator } from 'primeng/primeng';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
  providers: [ConfirmationService],
})
export class OfferComponent implements OnInit {
  hotels: SelectItem[];
  rooms: SelectItem[];
  searchInput: any = {};
  paginatorData: any = {};
  tableData: any;
  selectedDetail: any;
  cols: any[];
  isLoading = true;
  isdetailLoading = true;
  detailModal = false;
  editModal = false;
  detailForm: FormGroup;
  updateOfferForm: FormGroup;
  formSubmitted = false;
  addNewAgent: boolean = false;
  totalRecords: number = 0;
  currentPage: number = 1;
  page: number = 0;
  pageLimit: number = 15;
  pageCount: number = 1;
  agents: SelectItem[] = [];
  selectValue: string = 'yes';
  selectValue1: string = 'yes';
  selectValue2: string = 'yes';
  selectedValue: any;
  selectedValue2: any;
  selectedValue3: any;
  selectedHotel: any;
  selectedRoom: any;
  updateLandingForm: FormGroup;
  users: any;
  types: any;

  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.paginatorData = {
      first: 1,
      rows: 15,
      sortField: null,
      sortOrder: null,
    };
    this.cols = [
      { field: 'offerID', header: 'Offer' },
      { field: 'name', header: 'Name' },
      { field: 'totalPay', header: 'Total Amount' },
      { field: 'downPay', header: 'Down Payment' },
      { field: 'operation', header: 'Operation' },
      { field: 'agent', header: 'Agent' },
      { field: 'formatCreateDate', header: 'Create Date' },
      { field: 'detail', header: 'Detail' },
    ];
    this.getOffers({});
    this.getAgents();
    this.detailForm = this.formBuilder.group({
      detail: ['', [Validators.required]],
    });
    this.updateOfferForm = this.formBuilder.group({
      _id: [''],
      name: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      totalAmount: ['', [Validators.required]],
      downPayment: ['', [Validators.required]],
      hospitalStay: ['', [Validators.required]],
      operation: ['', [Validators.required]],
      anaesthesia: [''],
      hotel: ['', [Validators.required]],
      room: ['', [Validators.required]],
      roomDetail: ['', [Validators.required]],
      roomPrice: ['', [Validators.required]],
      attendant: ['', [Validators.required]],
      transferStatus: ['', [Validators.required]],
      transferPrice: ['', [Validators.required]],
      transferPackage: ['', [Validators.required]],
      agent: ['', [Validators.required]],
    });
    this.hotels = [
      { label: 'Volley Hotel Uskudar', value: 'Volley Hotel Uskudar' },
      { label: 'Marriott', value: 'Marriott' },
      {
        label: 'ParkInn by Radisson Ataşehir',
        value: 'ParkInn by Radisson Ataşehir',
      },
      { label: 'Mercure Hotel Altunizade', value: 'Mercure Hotel Altunizade' },
      { label: 'Antwell Hotel', value: 'Antwell Hotel' },
      {
        label: 'The Gate Hotel Kadıköy Downtown',
        value: 'The Gate Hotel Kadıköy Downtown',
      },
      { label: 'Asia City Hotel', value: 'Asia City Hotel' },
    ];
  }

  onPage(event: any) {
    this.paginatorData = event;
    console.log(event);
    if (Object.keys(this.searchInput).length > 0) {
      this.searchLogs(event);
    } else {
      this.getOffers(event);
    }
  }
  getOffers(event: any) {
    this.isLoading = true;
    this.currentPage = event.page + 1;
    this.adminService
      .getOfferswPagination(this.currentPage, this.pageLimit)
      .subscribe((data) => {
        const offers = data.offers;
        this.totalRecords = data.totalRecords;
        this.tableData = offers.map((item) => {
          return {
            ...item,
            formatCreateDate: moment(item.createDate).format(
              'DD.MM.YYYY HH:mm'
            ),
            totalPay: item.totalAmount + ' ' + item.currency,
            downPay: item.downPayment + ' ' + item.currency,
          };
        });
        this.isLoading = false;
        // this.showDetails(this.tableData[0]);
      });
  }

  triggerShowDetails(index: number) {
    if (index >= 0 && index < this.tableData.length) {
      this.showDetails(this.tableData[index]);
    }
  }

  showDetails(item) {
    this.detailModal = true;
    this.isdetailLoading = true;

    const {
      hotel,
      room,
      validDate,
      anaesthesia,
      roomDetail,
      roomPrice,
      attendant,
      transferStatus,
      transferPrice,
      transferPackage,
    } = item;

    this.selectedDetail = {
      validDate,
      anaesthesia,
      hotel,
      room,
      roomDetail,
      roomPrice,
      attendant,
      transferStatus,
      transferPrice,
      transferPackage,
    };

    this.isdetailLoading = false;
  }

  searchLogs(event: any) {
    this.isLoading = true;
    this.currentPage = event.page + 1;
    this.adminService
      .searchLogs(this.currentPage, this.pageLimit, this.searchInput)
      .subscribe((data) => {
        const logs = data.logs;
        this.totalRecords = data.totalRecords;
        this.tableData = logs.map((item) => {
          return {
            ...item,
            // formattedDate: moment(item.createdDate).format('DD.MM.YYYY HH:mm')
          };
        });
        this.isLoading = false;
      });
  }

  editRow(data) {
    if (data.attendant === true) {
      this.selectedValue = 'true';
    } else if (data.attendant === false) {
      this.selectedValue = 'false';
    }
    if (data.transferStatus === true) {
      this.selectedValue2 = 'true';
    } else if (data.transferStatus === false) {
      this.selectedValue2 = 'false';
    }
    if (data.transferPackage === true) {
      this.selectedValue3 = 'true';
    } else if (data.transferPackage === false) {
      this.selectedValue3 = 'false';
    }
    const value = {
      _id: data._id,
      name: data.name,
      currency: data.currency,
      totalAmount: data.totalAmount,
      downPayment: data.downPayment,
      hospitalStay: data.hospitalStay,
      operation: data.operation,
      anaesthesia: data.anaesthesia,
      hotel: data.hotel,
      room: data.room,
      roomDetail: data.roomDetail,
      roomPrice: data.roomPrice,
      attendant: this.selectedValue,
      transferStatus: this.selectedValue2,
      transferPrice: data.transferPrice,
      transferPackage: this.selectedValue3,
      agent: data.agent._id,
    };
    this.updateOfferForm.patchValue(value);
    this.editModal = true;
    this.selectedHotel = data.hotel;
    if (this.selectedHotel === 'Antwell Hotel') {
      this.rooms = [
        { label: 'Standard Room', value: 'Standard Room' },
        { label: 'Suite Room', value: 'Suite Room' },
      ];
    } else if (this.selectedHotel === 'Marriott') {
      this.rooms = [
        { label: 'Deluxe Room', value: 'Deluxe Room' },
        { label: 'Twin Deluxe Room', value: 'Twin Deluxe Room' },
        {
          label: 'Executive King Guest Room',
          value: 'Executive King Guest Room',
        },
        { label: 'Twin Executive Room', value: 'Twin Executive Room' },
        {
          label: 'Executive Suite - Bedroom',
          value: 'Executive Suite - Bedroom',
        },
        {
          label: 'Executive Suite - Living Room & Kitchenette',
          value: 'Executive Suite - Living Room & Kitchenette',
        },
      ];
    } else if (this.selectedHotel === 'Volley Hotel Uskudar') {
      this.rooms = [
        { label: 'Standard Room', value: 'Standard Room' },
        { label: 'Superior Room', value: 'Superior Room' },
        { label: 'Executive Room', value: 'Executive Room' },
        { label: 'Suite Room', value: 'Suite Room' },
      ];
    } else if (this.selectedHotel === 'ParkInn by Radisson Ataşehir') {
      this.rooms = [
        { label: 'Standard Room', value: 'Standard Room' },
        {
          label: 'Superior Room with Terrace',
          value: 'Superior Room with Terrace',
        },
        { label: 'Junior Suite', value: 'Junior Suite' },
        {
          label: 'One Bedroom Suite with Kitchenette',
          value: 'One Bedroom Suite with Kitchenette',
        },
        {
          label: 'Two Bedroom Suite with Kitchenette',
          value: 'Two Bedroom Suite with Kitchenette',
        },
        { label: 'Familiy Room', value: 'Familiy Room' },
      ];
    } else if (this.selectedHotel === 'Mercure Hotel Altunizade') {
      this.rooms = [
        { label: 'Standard Twin Room', value: 'Standard Twin Room' },
        { label: 'Standard Double Room', value: 'Standard Double Room' },
        { label: 'Superior Room', value: 'Superior Room' },
        { label: 'Suite Room', value: 'Suite Room' },
      ];
    } else if (this.selectedHotel === 'The Gate Hotel Kadıköy Downtown') {
      this.rooms = [
        { label: 'Business Room', value: 'Business Room' },
        { label: 'Kitchenette Suite', value: 'Kitchenette Suite' },
      ];
    } else if (this.selectedHotel === 'Asia City Hotel') {
      this.rooms = [
        { label: 'Standard Room', value: 'Standard Room' },
        { label: 'Deluxe Room', value: 'Deluxe Room' },
        { label: 'Suite Room', value: 'Suite Room' },
      ];
    }
  }

  onHotelChange() {
    // Reset selected room when the hotel changes
    // Update room options based on the selected hotel
    if (this.selectedHotel === 'Antwell Hotel') {
      this.rooms = [
        { label: 'Standard Room', value: 'Standard Room' },
        { label: 'Suite Room', value: 'Suite Room' },
      ];
    } else if (this.selectedHotel === 'Marriott') {
      this.rooms = [
        { label: 'Deluxe Room', value: 'Deluxe Room' },
        { label: 'Twin Deluxe Room', value: 'Twin Deluxe Room' },
        {
          label: 'Executive King Guest Room',
          value: 'Executive King Guest Room',
        },
        { label: 'Twin Executive Room', value: 'Twin Executive Room' },
        {
          label: 'Executive Suite - Bedroom',
          value: 'Executive Suite - Bedroom',
        },
        {
          label: 'Executive Suite - Living Room & Kitchenette',
          value: 'Executive Suite - Living Room & Kitchenette',
        },
      ];
    } else if (this.selectedHotel === 'Volley Hotel Uskudar') {
      this.rooms = [
        { label: 'Standard Room', value: 'Standard Room' },
        { label: 'Superior Room', value: 'Superior Room' },
        { label: 'Executive Room', value: 'Executive Room' },
        { label: 'Suite Room', value: 'Suite Room' },
      ];
    } else if (this.selectedHotel === 'ParkInn by Radisson Ataşehir') {
      this.rooms = [
        { label: 'Standard Room', value: 'Standard Room' },
        {
          label: 'Superior Room with Terrace',
          value: 'Superior Room with Terrace',
        },
        { label: 'Junior Suite', value: 'Junior Suite' },
        {
          label: 'One Bedroom Suite with Kitchenette',
          value: 'One Bedroom Suite with Kitchenette',
        },
        {
          label: 'Two Bedroom Suite with Kitchenette',
          value: 'Two Bedroom Suite with Kitchenette',
        },
        { label: 'Familiy Room', value: 'Familiy Room' },
      ];
    } else if (this.selectedHotel === 'Mercure Hotel Altunizade') {
      this.rooms = [
        { label: 'Standard Twin Room', value: 'Standard Twin Room' },
        { label: 'Standard Double Room', value: 'Standard Double Room' },
        { label: 'Superior Room', value: 'Superior Room' },
        { label: 'Suite Room', value: 'Suite Room' },
      ];
    } else if (this.selectedHotel === 'The Gate Hotel Kadıköy Downtown') {
      this.rooms = [
        { label: 'Business Room', value: 'Business Room' },
        { label: 'Kitchenette Suite', value: 'Kitchenette Suite' },
      ];
    } else if (this.selectedHotel === 'Asia City Hotel') {
      this.rooms = [
        { label: 'Standard Room', value: 'Standard Room' },
        { label: 'Deluxe Room', value: 'Deluxe Room' },
        { label: 'Suite Room', value: 'Suite Room' },
      ];
    }
  }

  updateOffer() {
    console.log(this.updateOfferForm.value);
    if (this.updateOfferForm.valid) {
      this.isLoading = true;
      console.log(this.updateOfferForm.value);
      this.adminService.updateOffer(this.updateOfferForm.value).subscribe(
        () => {
          this.formSubmitted = false;
          this.isLoading = false;
          this.editModal = false;
          this.getOffers({});
          this.updateOfferForm.reset();
        },
        () => {
          this.formSubmitted = false;
          this.isLoading = false;
        }
      );
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }

  getAgents() {
    this.adminService.getAgents().subscribe((data) => {
      const hede = [];
      data.map((line) => {
        if (!line.hidden) {
          hede.push({ label: line.name, value: line._id });
        }
      });
      this.agents = hede;
      console.log(this.agents);
    });
  }

  clearSearchForm() {
    this.searchInput = {};
    this.getOffers({ page: 0 });
  }

  // getUsers() {
  //   this.adminService.getUsers().subscribe(data => {
  //     console.log(data, "<<<<<users>>>>>");
  //     const hede = [];
  //     data.map((line) => {
  //         hede.push({label: line.name, value: line._id});
  //     });
  //     this.users = hede;
  //   });
  // }
  // getLog() {
  //   this.adminService.getLogs().subscribe(data => {
  //     const types = [];

  //     for (const item of data) {
  //       if (!types.includes(item.type)) {
  //         types.push(item.type);
  //       }
  //     }
  //     this.types = types.map(type => ({ label: type, value: type }));
  //   });
  // }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { AdminService } from "../_services/admin.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../_services/auth.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { NotificationService } from "../_services/notification.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [ConfirmationService],
})
export class DashboardComponent implements OnInit {
  searchInput: any = ({});
  teamNames: string[] = [];
  coloR: any[] = [];
  coloR1: any[] = [];
  coloR2: any[] = [];
  coloR3: any[] = [];
  agentNames: string[] = [];
  days: string[] = [];
  depositDays: string[] = [];
  totalCounts: number[] = [];
  stages: string[] = [];
  deals: number[] = [];
  leadCounts: number[] = [];
  leadsCount: number[] = [];
  leadCountswDate: number[] = [];
  isLoading = true;
  isLoading2 = true;
  isLoading3 = true;
  isLoading4 = true;
  agents: any;
  users: any;
  teams: any;
  data: any;
  data1: any;
  data2: any;
  data3: any;
  data4: any;
  role: string;
  agentsTable: SelectItem[] = [
    { label: 'All', value: null }
  ];

  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.getLogs(event);
    this.getLeads(event);
    this.getLeadswDate(event);
    this.getDepositTotal(event);
    this.dashboardValues();
    this.chartValues()
    this.barValues()
    this.depositValues()
    this.getAgents()
    this.getAgentDeals(event)
    this.role = this.authService.getRole();
  }

  getAgents() {
    this.adminService.getAgents().subscribe(data => {
      const hede = [];
      data.map((line) => {
        if(!line.hidden) {
          this.agentsTable.push({label: line.name, value: line._id});
        }
      });
    });
  }

  getLogs(event: any) {
    this.isLoading = true;

    this.teamNames = [];
    this.leadCounts = []; 

    this.adminService.getLeadswTeam(this.searchInput).subscribe((data) => {
      var dynamicColors = function() {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
     };
      data.forEach((line) => {
        if (line) {
          this.teamNames.push(line.name);
          this.leadCounts.push(line.lead);
        }
        this.coloR.push(dynamicColors());
      });
      this.dashboardValues();
    });
  }
  getLeads(event: any) {
    this.isLoading = true;

    this.agentNames = [];
    this.leadsCount = [];
    
    this.adminService.getLeadswAgent(this.searchInput).subscribe((data) => {
      var dynamicColors = function() {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
     };
      data.forEach((item) => {
        if (item) {
          this.agentNames.push(item.name);
          this.leadsCount.push(item.lead);
        }
        this.coloR1.push(dynamicColors());
      });
      this.chartValues();
    });
  }
  getLeadswDate(event: any) {
    this.isLoading = true;
    // Dizileri temizle
    this.days = [];
    this.leadCountswDate = [];
    this.coloR = [];
    this.adminService.getLeadswDate(this.searchInput).subscribe((data) => {
      var dynamicColors = function() {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
     };
      data.forEach((item) => {
        if (item) {
          this.days.push(item.date);
          this.leadCountswDate.push(item.lead);
        }
        this.coloR2.push(dynamicColors());
      });
      this.barValues();
    });
  }

  getDepositTotal(event: any) {
    this.isLoading2 = true;

    this.depositDays = [];
    this.totalCounts = [];
    
    this.adminService.getDeposit(this.searchInput).subscribe((data) => {
      var dynamicColors = function() {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
     };
      data.forEach((item) => {
        if (item) {
          this.depositDays.push(item.date);
          this.totalCounts.push(item.totalCount);
        }
        this.coloR3.push(dynamicColors());
      });
      this.depositValues();
    });
  }

  getAgentDeals(event: any) {
    this.isLoading4 = true;

    this.stages = [];
    this.deals = [];
    
    this.adminService.getAgentsDeals(this.searchInput).subscribe((data) => {
      var dynamicColors = function() {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
     };
      data.forEach((item) => {
        if (item) {
          this.stages.push(item.stage);
          this.deals.push(item.deals);
        }
        this.coloR3.push(dynamicColors());
      });
      this.dealsValues();
    });
  }
  
  leadsDateRange(event: any) {
    this.isLoading = true;
    this.getLeadswDate(event);
    this.getLogs(event);
    this.getLeads(event);
    this.getDepositTotal(event);
  }

  onAgentChange(event: any) {
    this.isLoading2 = true
    this.searchInput.id = event.value;
    this.getDepositTotal(event)
  }

  onChange(event: any) {
    this.isLoading4 = true
    this.searchInput.agent = event.value;
    this.getAgentDeals(event)
  }

  dashboardValues() {
    this.data = {
      labels: this.teamNames,
      datasets: [
        {
          data: this.leadCounts,
          backgroundColor: this.coloR,
          hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
        },
      ],
    };
    this.isLoading = false;
  }

  chartValues() {
    this.data1 = {
      labels: this.agentNames,
      datasets: [
        {
          data: this.leadsCount,
          backgroundColor: this.coloR1,
          hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
          label: 'Agent Week Leads',
        },
      ],
    };
    this.isLoading = false;
  }
  barValues() {
    this.data2 = {
      labels: this.days,
      datasets: [
        {
          data: this.leadCountswDate,
          backgroundColor: this.coloR2,
          hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
          label: 'Week Leads'
        },
      ],
    };
    this.isLoading = false;
  }
  depositValues() {
    this.data3 = {
      labels: this.depositDays,
      datasets: [
        {
          data: this.totalCounts,
          backgroundColor: this.coloR3,
          hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
          label: 'Week Deposit',
        }
      ],
    };
    this.isLoading2 = false;
  }

  dealsValues() {
    this.data4 = {
      labels: this.stages,
      datasets: [
        {
          data: this.deals,
          backgroundColor: this.coloR3,
          hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
          label: 'Stage by Stage Deals',
        }
      ],
    };
    this.isLoading4 = false;
  }

  clearSearchForm() {
    this.isLoading = true;
    this.searchInput = {};
    this.ngOnInit();
}
}
